$(document).on("click", "#submit-feedback", function () {

    $(".has-error").removeClass("has-error");
    $(".error").hide();
    if ($("#name").val() == "") {
        $("#name-error").show().html("Name is required").parent().addClass("has-error");
    }
    if ($("#email").val() == "") {
        $("#email-error").show().html("Email is required").parent().addClass("has-error");
    }
    if ($("#feedback").val() == "") {
        $("#feedback-error").show().html("Feedback is required").parent().addClass("has-error");
    }

    if ($(".has-error").length == 0) {
        $("#submit-feedback").val("Sending...");
        $.post("api/feedback", {
            PolicyId: $("#policy-id").val(),
            Name: $("#name").val(),
            Email: $("#email").val(),
            Message: $("#feedback").val()
        }, function (r) {
            $("#feedback-thankyou").removeClass("hidden").show();
            $("#feedback-form").hide();
        }, "json").fail(function (e) {
            //console.log("error", e);
            $("#general-error").html("Unable to submit feedback").show();
        }).always(function () {
            $("#submit-feedback").val("Submit");
        });;
    }
});