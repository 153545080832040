$(document).on("ready", function () {
    fetchPolicies();
    if ($("#policy-id").val()) {
        $("#search").html(            
            $("#policy-category-code").val()
            + " "
            + $("#policy-category").val()
            + " <span class=\"policy-name\">"
            + " <strong>" + $("#policy-code").val() + "</strong> "
            + $("#policy-topic").val()
            + "</span>"
        );
    }
    whoami();
});

$(document).on("keyup", "#search", function () {
    var query = strip(parseHtmlEnteties($(this).html())).replace("-","");
    var results = f.search(query);

    var res = "";
    if (query != "") {
        res = "<ul class=\"policy-list\">";
        if (results) {
            results.forEach(function (p) {
                res += '<li class="policy-item">'
                    + '<a href="/' + p.Code + '">'
                    + '<span class="code">' + p.Code + '</span>'
                    + p.Topic
                    + '</a>'
                    + '</li>';
            })
        } else {
            res = "<h4>Policies have not been fetched</h4>";
        }        
    }
    if (res == "") {
        res = "<h4>Start typing to perform a search...</h4>";
    }
    $("#search-results").html(res);
});
$(document).on("click", "#search", function () {
    activateSearch();
});
$(document).on("click", function (e) {
    if ($(e.target).closest(".search").length == 0 && $(e.target).closest("#search-results").length == 0 ) {
        $("body").removeClass("search-active");
    }
});
function whoami() {
    $.get("/api/me", function (u) {
        console.log(u);
        if (u.authenticated) {
            $("#sign-in").hide();
            $("#user-options").show();
            $("#user-name").html(u.name);
        }
    }).fail(function (r) {
        console.log("whoami fail", r);
    });
}
var f = null;
function activateSearch() {
    selectElementContents($("#search")[0]);
    $("body").addClass("search-active");

    f = new Fuse(policies, {
        keys: ['Code', 'Topic']
    });
}
var policies = {};
function fetchPolicies() {
    if (sessionStorage) {
        if (sessionStorage.getItem('policies')) {
            policies = JSON.parse(sessionStorage.getItem('policies'));
            return true;
        }
    }
    $.get("api/policies", null, "json")
    .done(function (r) {
        policies = r;
        if (sessionStorage) {
            sessionStorage.setItem('policies', JSON.stringify(policies));
        }
    })
    .fail(function (e) {
        console.log("Unable to fetch policies", e);
    });
}

function selectElementContents(el) {
    var range = document.createRange();
    range.selectNodeContents(el);
    var sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
}
function parseHtmlEnteties(str) {
    return str.replace(/&#([0-9]{1,3});/gi, function (match, numStr) {
        var num = parseInt(numStr, 10); // read num as normal number
        return String.fromCharCode(num);
    });
}
function strip(html) {
    var tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
}