var PolicyEditor = {
    policy: {},
    isEditing: false,
    fetchPolicy: function(id) {
        return $.get('/api/Policies/' + id);
    },
    addReference: function (name, url) {
        PolicyEditor.policy.References.push({
            "Name": name, "URL": url, "PolicyId": PolicyEditor.policy.PolicyId
        });
        PolicyEditor.updateView(PolicyEditor.policy, PolicyEditor.isEditing);
    },
    deleteReference: function (idx) {
        PolicyEditor.policy.References.splice(idx, 1);
        PolicyEditor.updateView(PolicyEditor.policy, PolicyEditor.isEditing);
    },
    enableEditing: function () {
        var id = $("#edit-policy").data("id");

        PolicyEditor.fetchPolicy(id).success(function (policy) {
            PolicyEditor.cancelPolicy = policy;
            PolicyEditor.policy = policy;
            PolicyEditor.isEditing = true;
            PolicyEditor.updateView(policy, PolicyEditor.isEditing);
        });
    },
    cancelEditing: function () {
        PolicyEditor.isEditing = false;
        PolicyEditor.updateView(PolicyEditor.cancelPolicy, false);
    },
    updateView: function (policy, editing) {
        console.log("updateView", policy);

        //if (policy.Attachments == null) {
         //   $("#policy-attachments").addClass("hidden");
        //}
        $("#policy-code").val(policy.Code);
        $("#policy-title").val(policy.Topic);

        if (policy.References == null) {
            $("#policy-references").addClass("hidden");
        } else {
            var html = "";
            for (var i = 0; i < policy.References.length; i++) {
                var r = policy.References[i];
                html += "<li>"
                    + "<a class=\"when-editing delete-item delete-reference\" data-index=\"" + i + "\"><i class=\"material-icons\">delete</i></a>"
                    + (r.URL ? "<a href=\"" + r.URL + "\">" : "")
                    + policy.References[i].Name
                    + (r.URL ? "</a>" : "")
                    + "</li>";
            }
            $("#policy-references-list").html(html);
        }

        if (editing) {
            $(".when-editing").show();
            $(".when-static").hide();

            //$("#policy-attachments").removeClass("hidden").show();
            $("#policy-references").removeClass("hidden").show();

            $("#policy-date").attr("class", "col-sm-3").show();
            $("#policy-date input").val(moment(policy.Date).format("MMM YYYY"));

            $("#policy-date-review").attr("class", "col-sm-3").show();
            $("#policy-date-review input").val(moment(policy.ReviewDate).format("MMM YYYY"));

            $("#policy-date-revised").attr("class", "col-sm-3").show();
            if ( policy.RevisedDate != undefined ) $("#policy-date-revised input").val(moment(policy.RevisedDate).format("MMM YYYY"));

            $("#policy-date-feedback").attr("class", "col-sm-3").show();
            if (policy.AcceptFeedbackFrom != undefined) $("#policy-date-feedback-from-field input").val(moment(policy.AcceptFeedbackFrom).format("MMM DD YYYY"));
            if (policy.AcceptFeedbackTo != undefined) $("#policy-date-feedback-to-field input").val(moment(policy.AcceptFeedbackTo).format("MMM DD YYYY"));

            $(".date-month input").datepicker({
                format: "M yyyy",
                startView: "months",
                minViewMode: "months"
            });
            $(".date input").datepicker({
                format: "M d yyyy"
            });

            $("#policy-content").html(policy.Html);
            tinymce.init({
                skin: false,
                inline: true,
                menubar: false,
                statusbar: false,
                selector: "#policy-content",
                toolbar: "styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | undo redo"
            });
        } else {
            $(".when-editing").hide();
            $(".when-static").show();

            var editor = tinyMCE.get('policy-content');
            if (editor != null) {
                editor.destroy();
            }
            $("#policy-content").html(policy.Html);
        }
    }
}


$(document).on("click", "#edit-policy", PolicyEditor.enableEditing);
$(document).on("click", "#cancel-policy", PolicyEditor.cancelEditing);

$(document).on("click", "#add-reference", function () {
    PolicyEditor.addReference($("#add-reference-name").val());
});
$(document).on("click", ".delete-reference", function () {
    PolicyEditor.deleteReference($(this).data("index"));
});